import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Col, Row, Form, Button, FormGroup, Label, Input, Spinner, Modal,
  ModalHeader,
  ModalBody } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { v4 as uuidv4 } from "uuid";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function EntradasCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_ENTRADAS = process.env.REACT_APP_URL_ENTRADAS;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;

  const [articulos, setArticulos] = useState([]);
  const [pedidos, setPedidos] = useState([]);
  const [selectedPedido, setSelectedPedido] = useState("");
  const [selectedCliente, setSelectedCliente] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [totalCantidad, setTotalCantidad] = useState(0);
  const [totalCaptura, setTotalCaptura] = useState(1);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [fecha, setFecha] = useState("");
  const [observaciones, setObservaciones] = useState("");

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      idArtPed: "",
      articulos: "",
      colores: "",
      pantone:"",
      cantidad: 0,
      captura:1,
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
   
   
      axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(`${URL_PEDIDOS}Activos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPedidos = res.data;
        setPedidos(allPedidos);
      })
      .catch((err) => {
        console.log(err);
      });

     
  }, []);

  const saveEntrada = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();
    let totalProd = inputFields.length

    try {
        await axios
        .post(
          URL_ENTRADAS,
          {
            fecha,
            clientes:  selectedCliente,
            operador: selectedColaborador,
            pedidos: selectedPedido,
            observaciones,
            totalCantidad,
            totalRollos: totalProd
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          
          inputFields.map((a) => {
            if(a.articulos != "" && a.cantidad !=0){
            axios.post(
              URL_INVENTARIOS,
              {
                entradas: data.data._id,
                pedidos: selectedPedido,
                articulos: a.articulos,
                colores: a.colores,
                cantidad: parseFloat(a.cantidad),
                articulosPedido: a.idArtPed,
                pantone: a.pantone,
                vendido: "No",
                apartado: "No",
                clientes:  selectedCliente,
                captura: a.captura,

              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then(()=>{
              totalProd = totalProd -1
              if(totalProd == 0 ){
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Crear Entrada",
                    detalle: `${data.data._id}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                    },
                  }
                )
                  .then(()=>{
                    Swal.fire("Good job!", "Creado con exito", `success`);
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
              }
            })
          }else{ 
            totalProd = totalProd -1
            if(totalProd == 0 ){
              axios.post(
                URL_LOGS,
                {
                  tipo: "Crear Entrada",
                  detalle: `${data.data._id}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                  },
                }
              )
                .then(()=>{
                  Swal.fire("Good job!", "Creado con exito", `success`);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                })
            }
          }
          });  
       
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  const handleAddFields = () => {
    let TC = totalCaptura +1
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        idArtPed: "",
      articulos: "",
      colores: "",
      pantone:"",
      cantidad: 0,
        captura:TC,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event){
    articulos.map((a)=>{
      if(a.idArtPed == event.target.value){
        let idArticulo = a.idArticulo
        let idArtPed = a.idArtPed
        let colores = a.colores
        handleChangeInputArticulo(id, idArticulo, idArtPed, colores)
      }
    })
  }

  const handleChangeInputArticulo = (id, idArticulo, idArtPed, colores) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.idArtPed = idArtPed;
        i.colores = colores
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

function jalaPedido(id){
  setSelectedPedido(id)
  pedidos.map((a)=>{
    if(a._id == id){
      setSelectedCliente(a.clientes[0]._id)
    }
  })


  axios
  .get(`${URL_ARTICULOS_PEDIDO}/pedidos/${id}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((res) => {
    const allArticulos = res.data;
    let array = allArticulos.map((a) => {
      return {
        idArtPed: a._id,
        idArticulo: a.articulos[0]._id,
        nombre: a.articulos[0].nombre,
        codigo: a.articulos[0].codigo,
        colores: a.colores
      }
    })

    let data = Object.values(array);
    setArticulos(data);
  })
  .catch((err) => {
    console.log(err);
  });


}

function ActualizaTotales(){
let THil = inputFields.map((c) => parseFloat(c.cantidad));
let Th = THil.reduce((t, total, index) => t + total, 0);
setTotalCantidad(Th)
}


  return (
    <>
      <Header />
      <br />
      <br />
      {user.produccion_create ? (
        <div className="card container col-12">
          <h3 align="center">Nueva Entrada</h3>
          <Form onSubmit={saveEntrada}>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Pedidos</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedPedido}
                  required
                  onChange={(e) => {
                    jalaPedido(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {pedidos
                    .sort((a, b) => (a.idPedido > b.idPedido ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.idPedido} {a.clientes[0].nombre_comercial}</option>;
                    })}
                </Input>
              </Col>

              <Col md={3}>
              <Label>Operador</Label>
              <Input
                type="select"
                value={selectedColaborador}
                onChange={(e) => {
                  setSelectedColaborador(e.target.value)
                }}
              >
                <option value="">Selecciona </option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    if(a.puestos[0].name != "Ventas"){
                      return <option value={a._id}>{a.nombre} {a.apellido}</option>;
                    }
                    
                  })}
              </Input>
            </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <br />
           

 
              <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Articulos</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Color</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Pantone</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Cantidad</Label>
                </Col>
              </Row>

              {inputFields.map((inputField) => (
                <div key={inputField.id}>
                  <Row>
                    <Col md={2}>
                      <Input
                        bsSize="sm"
                        name="articulos"
                        type="select"
                        value={inputField.idArtPed}
                        required
                        onChange={(event)=>{BuscaArticulo(inputField.id, event)}}
                      >
                        <option value="">Selecciona un Articulo</option>
                        {articulos
                          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a.idArtPed}>
                                {a.nombre} / {a.codigo} / {a.colores}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="colores"
                        type="text"
                        value={inputField.colores}
                        required
                        disabled
                      />
                    </Col>
                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="pantone"
                        type="text"
                        value={inputField.pantone}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={inputField.cantidad}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>

                    <Col>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        disabled={inputFields.length === 1}
                        onClick={() => handleRemoveFields(inputField.id)}
                        tabindex="-1"
                      >
                        <i class="fas fa-minus"></i>
                      </Button>
                      <Button 
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1">
                      <i class="fas fa-plus"></i>
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))}

        

           <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoEntradas"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
        <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default EntradasCreate;
